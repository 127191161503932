import React from 'react'

const className = (...arr) => arr.filter(Boolean).join(' ')

export const Field = React.forwardRef(({help, name, children, error, onChange, required, minLength}, ref) => {
    if (error) {
        help = error
    }
    return <div className={className('form-group', error && 'has-error')}>
        <label htmlFor={name} className="control-label">{children}</label>
        <textarea 
            ref={ref} 
            name={name} 
            id={name} 
            rows="5" 
            className="form-control" 
            onChange={onChange} 
            required={required}
            minLength={minLength}
            style={{backgroundColor: "rgba(192,192,192,0.3)"}}
        />
        {help && <div className="help-block mt-2">{help}</div>}
    </div>
})
