import {render, unmountComponentAtNode} from 'react-dom'
import React, {useState, useCallback, useEffect, useRef} from 'react'
import { useFetch, usePaginatedFetch } from '../hooks/hook'
import {Icon} from '../components/Icon'
import { Field } from '../components/Form'

const dateFormat = {
    dateStyle: 'medium',
    timeStyle: 'short'
}
const VIEW = 'VIEW'
const EDIT = 'EDIT'
function Comments ({subject, user}) {
    const {items: comments, setItems: setComments, load, loading, count, hasMore} = usePaginatedFetch('/api/subject_comments?subject=' + subject)
    const addComment = useCallback(comment => {
        setComments(comments => [comment, ...comments])
    }, [])
    const deleteComment = useCallback(comment => {
        setComments(comments => comments.filter(c => c !== comment))
    }, [])
    const updateComment = useCallback((newComment, oldComment) => {
        setComments(comments => comments.map(c => c === oldComment ? newComment : c))
    }, [])  
    useEffect(() => {
        load()
    }, [])
    return  <div className="p-5 border border-dark border-top-0 border-bottom-0" >
        <Title count={count}/>
        {user && <CommentForm subject={subject} onComment={addComment}/>}
        {comments.map(c =>
            <Comment 
                user={user}
                key={c.id} 
                comment={c} 
                canEdit={c.user.id === user} 
                onDelete={deleteComment} 
                onUpdate={updateComment}
            />
        )}
        {hasMore && <button className="btn btn-custom" disabled={loading} onClick={load}>Charger plus de commentaire</button> }
    </div>
}

const Comment = React.memo(({comment, onDelete, canEdit, onUpdate, user}) => {
    // Variables 
    const date = new Date(comment.createdAt) 
    const [replies, setReplies] = useState(comment.subjectCommentsReplies) 
    // Events
    const toggleEdit = useCallback(() => {
        setState(state => state === VIEW ? EDIT : VIEW)
    }, [])
    const onDeleteCallback = useCallback(() => {
        onDelete(comment)
    }, [comment])
    const onComment = useCallback((newComment) => {
        onUpdate(newComment, comment)
        toggleEdit()
    }, [comment])   

    //REPLY FUNC
    const addReply = useCallback(reply => {
        setReplies(replies => [...replies, reply])
    }, [])
    const deleteReply = useCallback(reply => {
        setReplies(replies => replies.filter(r => r !== reply))
    }, [])

    const updateReply = useCallback((newReply, oldReply) => {
        setReplies(replies => replies.map(r => r === oldReply ? newReply : r))
    }, [])
    // Hooks
    const [state, setState] = useState(VIEW)
    const {loading: loadingDelete, load: callDelete} = useFetch(comment['@id'], 'DELETE', onDeleteCallback)
    // Render
    return <div className="col d-flex flex-row my-2 p-2 bg-white border border-dark rounded">
        <h6 className="col-2 pt-4">
            <strong children={comment.user.firstname + " " + comment.user.lastname}/>
            <div>commenté le</div>
            <strong>{date.toLocaleString(undefined, dateFormat)}</strong> 
        </h6>
        <div className="col-10 border-left border-dark py-2">
            {state === VIEW ?
                <p className="border rounded border-secondary p-4" style={{backgroundColor: "rgba(192,192,192,0.3)"}}>{comment.body}</p> :
                <CommentForm comment={comment} onComment={onComment} onCancel={toggleEdit}/>
            }
            {(canEdit && state !== EDIT) && <p className="ml-4 my-2">
                <button className="btn btn-sm btn-custom" onClick={callDelete.bind(this, null)} disabled={loadingDelete}> 
                    <Icon icon="trash"/>Supprimer
                </button>
                <button className="btn btn-sm btn-secondary ml-2" onClick={toggleEdit}> 
                    <Icon icon="pen"/>Editer
                </button>
            </p>}
            <div className="mb-2">
                {replies.map(r => 
                    <Reply
                        key={r.id}
                        reply={r} 
                        user={user} 
                        canEdit={r.user.id === user} 
                        onUpdate={updateReply} 
                        onDelete={deleteReply}
                    />
                )}
            </div>
            <ReplyForm comment={comment} onReply={addReply} onCancel={toggleEdit}/>
        </div>
    </div>
})

const Reply = React.memo(({reply, canEdit, onDelete, onUpdate}) => {  
    // Variables 
    const ref = useRef(null)
    const date = new Date(reply.createdAt)  
    // Events
    const toggleEdit = useCallback(() => {
        setState(state => state === VIEW ? EDIT : VIEW)
    }, [])
    const onDeleteCallback = useCallback(() => {
        onDelete(reply)
    }, [reply])
    const onReply = useCallback((newReply) => {
        onUpdate(newReply, reply)
        toggleEdit()
    }, [reply]) 
 
    // Hooks
    const [state, setState] = useState(VIEW)
    const {loading: loadingDelete, load: callDelete} = useFetch(reply['@id'], 'DELETE', onDeleteCallback)
    return <div className="row m-2 p-2 mt-4 border-top border-dark">
        <h6 className="col-2 align-self-center">
            <strong children={reply.user.firstname + " " + reply.user.lastname}/>
            <div>commenté le</div>
            <strong>{date.toLocaleString(undefined, dateFormat)}</strong> 
        </h6>
        <div className="col-10 border-left border-dark py-2">
            {state === VIEW ?
                <p className="border rounded border-secondary p-4" style={{backgroundColor: "rgba(192,192,192,0.3)"}}>{reply.body}</p> :
                <ReplyForm reply={reply} onReply={onReply} onCancel={toggleEdit}/>
            }
            {(canEdit && state !== EDIT) && <p className="mb-0">
                <button className="btn btn-sm btn-custom" onClick={callDelete.bind(this, null)} disabled={loadingDelete}> 
                    <Icon icon="trash"/>Supprimer
                </button>
                <button className="btn btn-sm btn-secondary ml-2" onClick={toggleEdit}> 
                    <Icon icon="pen"/>Editer
                </button>
            </p>}
        </div>
    </div>

})

const ReplyForm = React.memo(({comment = null, onReply, reply = null, onCancel = null}) => {
    // Variables
    const ref = useRef(null)
    // Methodes
    const onSuccess = useCallback(reply => {
        onReply(reply)
        if (ref && ref.current != null) {
            ref.current.value = ''
        }
    }, [ref, onReply])
    // Hooks
    const method = reply ? 'PUT' : 'POST'
    const url = reply ? reply['@id'] : '/api/subject_comments_replies'
    const {load, loading, errors, clearError} = useFetch(url, method, onSuccess)
    const onSubmit = useCallback(e => {
        e.preventDefault()
        if (comment) {
            load({
                body: ref.current.value,
                comment: '/api/subject_comments/' + comment.id
            })
        } else {
            load({
                body: ref.current.value,
            })
        }
    }, [load, ref, comment])
    // Effects 
    useEffect(() => {
        if (reply && reply.body && ref.current) {
            ref.current.value = reply.body
        }
    }, [reply, ref])
    // render
    return <div className="well px-4 py-2 border-top border-bottom border-dark">
        <form onSubmit={onSubmit}>
            {reply === null && <fieldset>
                <legend><Icon icon="comment"/> Répondre au commentaire </legend>
            </fieldset>}
            <Field 
                name="body" 
                help="Les réponses non conformes seront modérées." 
                ref={ref} 
                required
                minLength={5}
                onChange={clearError.bind(this, 'body')}
                error={errors['body']}>
                Votre réponse
            </Field>
            <div className="form-group">
                <button className="btn btn-custom" disabled={loading}>
                    <Icon icon="paper-plane"/> {reply === null ? 'Envoyer' : 'Editer'}
                </button>
                {onCancel && <button className="btn btn-secondary" onClick={onCancel}>
                    Annuler
                </button>}
            </div>
        </form>
    </div>
})

const CommentForm = React.memo(({subject = null, onComment, comment = null, onCancel = null}) => {
    // Variables
    const ref = useRef(null)
    // Methodes
    const onSuccess = useCallback(comment => {
        onComment(comment)
        if (ref && ref.current != null) {
            ref.current.value = ''
        }
    }, [ref, onComment])
    // Hooks
    const method = comment ? 'PUT' : 'POST'
    const url = comment ? comment['@id'] : '/api/subject_comments'
    const {load, loading, errors, clearError} = useFetch(url, method, onSuccess)
    const onSubmit = useCallback(e => {
        e.preventDefault()
        if (subject) {
            load({
                body: ref.current.value,
                subject: '/api/subjects/' + subject
            })
        } else {
            load({
                body: ref.current.value,
            })
        }
    }, [load, ref, subject])
    // Effects 
    useEffect(() => {
        if (comment && comment.body && ref.current) {
            ref.current.value = comment.body
        }
    }, [comment, ref])
    // render
    return <div className="card p-4">
        <form onSubmit={onSubmit}>
            {comment === null && <fieldset>
                <legend><Icon icon="comment"/> Laisser un commentaire </legend>
            </fieldset>}
            <Field 
                name="body" 
                help="Les commentaires non conformes à notre code de conduite seront modérés." 
                ref={ref} 
                required
                minLength={5}
                onChange={clearError.bind(this, 'body')}
                error={errors['body']}>
                Votre commentaire
            </Field>
            <div className="form-group">
                <button className="btn btn-custom" disabled={loading}>
                    <Icon icon="paper-plane"/> {comment === null ? 'Envoyer' : 'Editer'}
                </button>
                {onCancel && <button className="btn btn-secondary" onClick={onCancel}>
                    Annuler
                </button>}
            </div>
        </form>
    </div>
})

function Title ({count}) {
    return <h4>
        <Icon icon="comments"/> 
            {count} Commentaire{count > 1 ? 's' : ''}
    </h4>
}

class CommentsElement extends HTMLElement {
    constructor () {
        super()
        this.observer = null
    }

    connectedCallback (){
        const subject = parseInt(this.dataset.subject, 10)
        const user = parseInt(this.dataset.user, 10 || null)
        if (this.observer === null) {
            this.observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && entry.target === this) {
                        observer.disconnect()
                        render (<Comments subject={subject} user={user} />, this)
                    }
                })
            })
        }
        this.observer.observe(this)
    }
    
    disconnectedCallback (){
        if (this.observe) {
            this.observe.disconnect()
        }
    }
}

customElements.define('comment-part', CommentsElement)